import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { Section, SectionHeading } from "../../UI/Common"
import {TextBlock} from "../../UI/Common"
import { contentCls } from "./StoreLocator.module.scss"
const StoreLocator = ({
  title,
  storeDetails: {
    address,
    iframe,
    postcode,
    phoneNumber,
    openingHours: { weekdays, saturday, sunday },
  },
  config,
}) => {

  const { padding } = config || {}
  return (
    <Section padding={padding}>
      <>
        <Container>
          <Row>
            <Col className="d-flex align-items-center">
              <SectionHeading text={title} />
            </Col>

            <Col
              className={`${contentCls} pt-3 p-lg-5 d-md-flex align-items-lg-center mb-lg-1`}>
              <Col>
                <strong>{address}</strong>
                <p>{postcode} </p>
                <p className="m-0">
                  <b className="pr-1">Phone:</b>
                  <small>
                    <a
                      className="text-decoration-none"
                      href={`tel:${phoneNumber}`}>
                      {phoneNumber}
                    </a>
                  </small>
                </p>
              </Col>
              <Col>
                <ul className="list-unstyled m-0">
                  <b>Opening Hours:</b>
                  <li>Monday - Friday: {weekdays}</li>
                  <li>Saturday: {saturday}</li>
                  <li>Sunday: {sunday}</li>
                </ul>
              </Col>
            </Col>
          </Row>
        </Container>
        <div className={`border border-success`}>
          {iframe && <TextBlock text={iframe} mode="map" /> }
        </div>
      </>
    </Section>
  )
}
export default StoreLocator
